
/** Generated by FG **/
@font-face {
	font-family: 'Conv_Comfortaa-Bold';
	src: url('Comfortaa-Bold.eot');
	src: local('☺'), url('Comfortaa-Bold.woff') format('woff'), url('Comfortaa-Bold.ttf') format('truetype'), url('Comfortaa-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/** Generated by FG **/
@font-face {
	font-family: 'Conv_Comfortaa-Light';
	src: url('Comfortaa-Light.eot');
	src: local('☺'), url('Comfortaa-Light.woff') format('woff'), url('Comfortaa-Light.ttf') format('truetype'), url('Comfortaa-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/** Generated by FG **/
@font-face {
	font-family: 'Conv_Comfortaa-Medium';
	src: url('Comfortaa-Medium.eot');
	src: local('☺'), url('Comfortaa-Medium.woff') format('woff'), url('Comfortaa-Medium.ttf') format('truetype'), url('Comfortaa-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/** Generated by FG **/
@font-face {
	font-family: 'Conv_Comfortaa-Regular';
	src: url('Comfortaa-Regular.eot');
	src: local('☺'), url('Comfortaa-Regular.woff') format('woff'), url('Comfortaa-Regular.ttf') format('truetype'), url('Comfortaa-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/** Generated by FG **/
@font-face {
	font-family: 'Conv_Comfortaa-SemiBold';
	src: url('Comfortaa-SemiBold.eot');
	src: local('☺'), url('Comfortaa-SemiBold.woff') format('woff'), url('Comfortaa-SemiBold.ttf') format('truetype'), url('Comfortaa-SemiBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


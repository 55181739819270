/* Modal Header */
.my_modal-header {
    padding: 20px 16px;
    background-color: #5cb85c;
    color: white;
  }
  
  .my_modal-header div.title {
    font-size: 20px;
  }

  .my_modal-header span.close {
      font-size:  30px;
  }

  /* Modal Body */
  .my_modal-body {padding: 0 20px;}
  
  /* Modal Footer */
  .my_modal-footer {
    margin-top: 5%;
    padding: 2px 16px 32px;
    color: white;
  }
  
  /* Modal Content */
  .my_modal-content {
    position: relative;
    background-color: #fefefe;
    top: 10%;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
/* Modal Content */
.my_modal-outside {
    position: absolute;
    background-color: #00000060;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}
  /* Add Animation */
  @keyframes animatetop {
    from {top: -100px; opacity: 0}
    to {top: 10%; opacity: 1}
  }


.modal_buttons .active_close {
    color: #fff !important;
    background: gray !important;
    outline: gray !important;
}

.modal_buttons button {
    max-width: 180px;
    margin: -4px;
}

@media only screen and (max-width: 575px) {
    .modal_buttons button {
        padding: 0px 0px !important;
        margin: -4px;
        max-width: 150px;
        height: 37px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: 12px !important;
    }
}

.my_notes_section textarea {
    width: 100% !important;
    padding: 2% 20px;
}

.my_notes_section label {
    width: 114px !important;
}

.my_notes_section {
    align-items: center;
    justify-content: space-between;
    margin-top: 5%;
}

.my_contact_section {
    align-items: center;
    justify-content: space-between;
    margin-top: 5%;
}

.my_contact_section input {
    width: 100% !important;
    padding: 2% 20px;
}
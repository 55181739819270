* {
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
    padding: 0;
}

.main_left_slider {
    max-width: 260px !important;
    background: #8bc34a;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 13px;
    right: 0;
    z-index: 999;
}

/* .container {
    margin: 0;
    padding: 0;
} */

.side_panel {
    display: none;
}

.user_details {
    display: flex;
    align-items: flex-start;
    grid-gap: 11px;
    grid-gap: 11px;
    gap: 11px;
    justify-content: center;
    padding: 25px 0px;
}

.brand_logo {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 14px;
    justify-content: center;
}

.brand_logo p {
    margin: 0;
}

.brand_logo .logo_icon {
    background: #80008075;
    padding: 7px 10px;
    border-radius: 50%;
}

.user_name h3 {
    font-size: 20px;
}

.user_img p {
    background: red;
    padding: 10px;
    border-radius: 50%;
}

.user_name p {
    font-size: 14px;
    color: silver;
}

.menu_icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.brand_logo img {
    max-width: 130px;
}

.menu_icons p {
    margin: 0;
}

.menu_icons p {
    margin: 0;
    font-size: 17px;
    font-weight: 600;
}

.sidepanel_profile_img {
    width: 37px;
    border-radius: 50%;
}

@media only screen and (max-width: 575px) {
    .main_left_slider {
        max-width: 200px !important;
    }

    .menu_icons {
        padding: 10px 10px;
    }

    .brand_logo img {
        max-width: 100px;
    }

    .user_name h3 {
        font-size: 16px;
    }

    .sidepanel_profile_img {
        width: 30px;
    }

    .user_name p {
        font-size: 12px;
    }

    .user_details {
        padding: 15px 0px;
    }

    .menu_icons p {
        font-size: 15px;
    }
}
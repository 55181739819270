.kiwi-modal .modal-content {
  min-height: 430px;
}

.trackOrder-subheader {
  font-size: 18px;
  line-height: 21px;
  color: #262626;
  margin-bottom: 36px;
}

.trackOrder-header {
  font-family: "Conv_Comfortaa-Regular";
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .trackOrder-subheader {
    margin-bottom: 100px;
  }
}
.store-btn {
  cursor: pointer;
}

.hero-section {
  background: url("../../../../../Assets/Images/hero image.png") no-repeat;
  background-size: cover;
  position: relative;
  padding: 36px 0;
}

.overlay-bg-black {
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  inset: 0;
}


.hero-header {
  font-family: 'Conv_Comfortaa-Regular';
  font-weight: bold;
  font-size: calc(40px + (72 - 40) * ((100vw - 300px) / (1920 - 300)));
  line-height: 80px;
  color: #f4f1f8;
  margin-top: 53px;
}

.hero-subHeader {
  font-size: 36px;
  color: #f4f1f8;
  margin-bottom: 95px;
}
.offer_1{
  margin-bottom: 36px;
}
.offer_2{
  margin-bottom: 31px;
}
.offer_1,
.offer_2{
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  width: 596px;
  padding: 20px;
}
.offer_1 img,
.offer_2 img{
  margin-right: 30px;
}
.offer_1 .offer-text,
.offer_2 .offer-text{
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #262626;
}

.download-op{
  margin-bottom: 20px;
}
.download-op img {
  cursor: pointer;
}
.download-op img:last-child{
  margin-left: 30px;
}
.hero-form {
  background: #ffffff;
  border-radius: 20px;
  padding: 24px 81px 15px;
  float: right;
}

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 10px 0 20px !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  height: 50px !important;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  border-bottom: 1px solid #828282 !important;
  border-radius: 0 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group .form-control:focus {
  box-shadow: none;
  border:none;
}

.form-group > input,
.form-group > select,
.form-group > label {
  padding: var(--input-padding-y) 0 5px !important;
  padding-left: 25px !important;
}
.contact-us .form-group > input,
.contact-us .form-group > select,
.contact-us .form-group > label {
  padding: var(--input-padding-y) 0 5px !important;
  padding-left: 0px !important;
}
.contact-us .form-group > label {
  top: 5px;
  color: #828282;
}
.contact-us .form-group .form-control:not(:placeholder-shown) ~ label {
  top: 0;
}
.form-group .form-control:not(:placeholder-shown)  .edit{
  top: 0 !important;
}

.form-group > img {
  position: relative;
  top: 42px;
}
.form-group .arrow_up_right {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.form-group > label {
  position: absolute;
  top: 28px;
  left: 0;
  margin-bottom: 0;
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 21px;
  color: #262626;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  transition: all 0.1s ease-in-out;
}
.detail-inp > label {
  position: absolute;
  top: 6px;
  left: 0;
  margin-bottom: 0;
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 21px;
  color: #262626;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  transition: all 0.1s ease-in-out;
}

.detail-inp .form-control:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3) !important;
  padding-bottom: calc(var(--input-padding-y) / 3) !important;
  font-size: 12px;
  line-height: 15px;
  color: #262626;
  top: -8px !important;
}


.form-group .form-control::placeholder {
  color: transparent;
}

.form-group .form-control:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3)) !important;
  padding-bottom: calc(var(--input-padding-y) / 3) !important;
}

.form-group .form-control:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3) !important;
  padding-bottom: calc(var(--input-padding-y) / 3) !important;
  font-size: 12px;
  line-height: 15px;
  color: #262626;
  top: 15px;
}
.form-group .form-control ~ .leave {
  top: 0 !important;
}
.kiwi-modal .form-group .form-control ~ label {
  padding-left: 0;
  top: 5px !important;
  font-size: 16px;
}
.kiwi-modal .form-group .form-control:not(:placeholder-shown) ~ label {
  top: -4px !important;
  font-size: 12px;
}
.kiwi-modal .form-group > input,
.kiwi-modal .form-group > select,
.kiwi-modal .form-group > label {
  padding-left: 0 !important;
}
.price {
  font-size: 18px;
  line-height: 19px;
  color: #262626;
  font-style: italic;
}
.price span:last-child {
  font-weight: bold;
  font-size: 18px;
  color: #0BDC00;
}
.info img{
  margin-right: 8px;
}
.info{
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}
.info h6{
  font-size: 18px;
  line-height: 21px;
  color: #262626;
  font-style: italic;
}
.offer-img-1{
  position: absolute;
  bottom: -100px;
  right: 0;
}


.captcha {
  background-color:#f9f9f9;
  border:2px solid #d3d3d3;
  border-radius:5px;
  width: 302px;
  height: 76px;
  color:#4c4a4b;
  display:flex;
  justify-content:center;
  align-items:center;
}
.text {
  font-size:16px;
  font-weight:500;
  margin-right:1em;
}
.spinner {
  position:relative;
  width:24px;
  height:24px;
  display:flex;
  margin:2em 1em;
  align-items:center;
  justify-content:center;
}
.spinner label{
  margin-bottom: 0;
}
input[type="checkbox"] { position: absolute; opacity: 0; z-index: -1; }
input[type="checkbox"]+.checkmark {
  display:inline-block;
  width:24px;
  height:24px;
  background-color:#fcfcfc;
  border:2.5px solid #c3c3c3;
  border-radius:3px;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
}
input[type="checkbox"]+.checkmark span {
  content:'';
  position:relative;/*
	position:absolute;
	border-bottom:3px solid;
	border-right:3px solid;
	border-color:#029f56;*/
  margin-top:-3px;
  transform:rotate(45deg);
  width:.75em;
  height:1.2em;
  opacity:0;
}
input[type="checkbox"]+.checkmark>span:after {
  content:'';
  position:absolute;
  display:block;
  height:3px;
  bottom:0;left:0;
  background-color:#029f56;
}
input[type="checkbox"]+.checkmark>span:before {
  content:'';
  position:absolute;
  display:block;
  width:3px;
  bottom:0;right:0;
  background-color:#029f56;
}
input[type="checkbox"]:checked+.checkmark {
  animation:2s spin forwards;
}
input[type="checkbox"]:checked+.checkmark>span {
  animation:1s fadein 1.9s forwards;
}
input[type="checkbox"]:checked+.checkmark>span:after {animation:.3s bottomslide 2s forwards;}
input[type="checkbox"]:checked+.checkmark>span:before {animation:.5s rightslide 2.2s forwards;}
@keyframes fadein {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes bottomslide {
  0% {width:0;}
  100% {width:100%;}
}
@keyframes rightslide {
  0% {height:0;}
  100% {height:100%;}
}
.logo {
  display:flex;
  flex-direction:column;
  align-items:center;
  height:100%;
  align-self:flex-end;
  margin: 0 .5em;
}
.logo{
  justify-content: center;
}
.logo img {
  height:28px;
  width:28px;
}
.logo p {
  color:#9d9ba7;
  margin:0;
  font-size:10px;
  font-weight:700;
}
.logo small {
  color:#9d9ba7;
  margin:0;
  font-size: 10px;
}
@keyframes spin {
  10% {
    width:0;
    height:0;
    border-width:6px;
  }
  30% {
    width:0;
    height:0;
    border-radius:50%;
    border-width:1em;
    transform: rotate(0deg);
    border-color:rgb(199,218,245);
  }
  50% {
    width:2em;
    height:2em;
    border-radius:50%;
    border-width:4px;
    border-color:rgb(199,218,245);
    border-right-color:rgb(89,152,239);
  }
  70% {
    border-width:4px;
    border-color:rgb(199,218,245);
    border-right-color:rgb(89,152,239);
  }
  90% {
    border-width:4px;
  }
  100% {
    width:2em;
    height:2em;
    border-radius:50%;
    transform: rotate(720deg);
    border-color:transparent;
  }
}
.sender_popup{
  background: #FFFFFF;
  box-shadow: 0px 0px 33px 19px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  padding: 20px 5px;
  position: absolute;
  z-index: 99;
  width: 340px;
  right: -100px;
  top: 45px;
}
.sender_popup .back{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.sender_popup .form-control {
  background: none !important;
}
.switch {
  display: inline-flex;
  margin: 0 10px;
}
.switch input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.switch input[type=checkbox]:checked + label {
  background: #9EFFA8;
  border: 2px solid #0BDC00;
}
.switch input[type=checkbox]:checked + label::after {
  left: calc(100% - 4px);
  transform: translateX(-100%);
  background: #262626;
}
.switch label {
  cursor: pointer;
  width: 48px;
  height: 24px;
  background: grey;
  border: 2px solid grey;
  display: block;
  border-radius: 24px;
  position: relative;
}
.switch span {
  margin-left: 10px;
  font-size: 16px;
  color: #828282;
}
.switch label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 16px;
  transition: 0.3s;
}
.sender_popup .form-group .form-control:not(:placeholder-shown) ~ label {
  top: 0px !important;
  font-size: 12px;
}
.sender_popup .form-group label {
  top: 5px !important;
  font-size: 16px;
  color: #262626;
}
.sender_popup .form-group > input,
.sender_popup .form-group > select,
.sender_popup .form-group > label {
  padding: var(--input-padding-y) 0 5px !important;
  padding-left: 0px !important;
}


.receiver_popup{
  background: #FFFFFF;
  box-shadow: 0px 0px 33px 19px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  padding: 20px 5px;
  position: absolute;
  z-index: 99;
  width: 340px;
  right: -100px;
  top: 155px;
}
.receiver_popup .back{
  position: absolute;
  top: 5px;
  right: 5px;
}
.receiver_popup .form-control {
  background: none !important;
}
.receiver_popup .form-group .form-control:not(:placeholder-shown) ~ label {
  top: 0px !important;
  font-size: 12px;
}
.receiver_popup .form-group label {
  top: 5px !important;
  font-size: 16px;
  color: #262626;
}
.receiver_popup .form-group > input,
.receiver_popup .form-group > select,
.receiver_popup .form-group > label {
  padding: var(--input-padding-y) 0 5px !important;
  padding-left: 0px !important;
}




@media screen and (max-width: 576px) {
  .captcha {
    width: 100%;
  }
  .text {
    margin:.5em!important;
    text-align:center;
  }
  .logo {
    align-self: center!important;
  }
  .spinner {
    margin:.5em .5em .5em .5em!important;
  }
  .contact-us .btn-green{
    width: 100% !important;
  }
}




@media (max-width: 767px) {
  .hero-section {
    padding: 20px 19px 61px;
  }
  .hero-header {
    line-height: 50px;
    text-align: center;
    margin-bottom: 15px;
  }
  .hero-subHeader {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    padding: 0 10px;
    margin-bottom: 47px;
  }
  .offer_1,
  .offer_2{
    width: 536px !important;
  }
  .offer_1 .offer-text,
  .offer_2 .offer-text{
    font-size: 24px;
  }
  .download-op img {
    margin: 0 auto 10px !important;
    display: block;
  }
  .receiver_popup,
  .sender_popup{
    right: 16px !important;
    width: 90% !important;
  }
}

@media (max-width: 991px) {
  .hero-form {
    width: 100%;
    padding: 83px 20px 20px;
  }
  .hero-header {
    line-height: 60px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .hero-subHeader {
    font-size: 26px;
    line-height: 45px;
    margin-bottom: 20px;
  }
  .offer_1,
  .offer_2{
    width: 100% !important;
  }
  .offer_1 .offer-text,
  .offer_2 .offer-text{
    font-size: 26px;
  }
  .download-op img {
    margin: 0 auto 10px !important;
    display: block;
  }
  .receiver_popup,
  .sender_popup{
    right: 16px !important;
    width: 90% !important;
    top: 280px;
  }
  .receiver_popup{
    top: 370px !important;
  }
}

@media (max-width: 1200px) {
  .hero-form {
    padding: 24px 31px 15px;
    width: 100%;
  }
  .offer_1,
  .offer_2{
    width: 536px;
  }
  .receiver_popup,
  .sender_popup{
    right: 16px;
    width: 100% ;
  }
}
@media (min-width: 1200px) {
  .receiver_popup,
  .sender_popup{
    right: 16px;
    width: 340px;
  }
}
@media (min-width: 1439px) {
  .hero-form {
    width: 500px !important;
  }
}
.footer {
  min-height: 312px;
  background: #262626 !important;
  position: relative;
  padding: 83px 0;
  color: #ffffff;
}



.footer-mask-group {
  position: absolute;
  right: 0;
  bottom: 0;
}

.footer-header {
  font-family: 'Conv_Comfortaa-Regular';
  font-size: 24px;
  line-height: 27px;
  color: #f4f1f8;
  margin-bottom: 15px;
}

.footer-subHeader {
  font-size: 18px;
  line-height: 21px;
  color: #f4f1f8;
}

.footer-subHeader:last-child {
  cursor: pointer;
}

.footer-subHeader:not(:last-child) {
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .footer {
    padding: 31px 0;
  }

  .footer-header {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 20px;
  }

  .footer-subHeader {
    font-size: 16px;
    line-height: 19px;
  }

  .footer-subHeader:not(:last-child) {
    margin-bottom: 20px;
  }
}
.join-us {
    background-size: 100% 100%;
    height: 500px;
}
.join-us-sender {
    background: url("../../../../../Assets/Images/motor pc.png") no-repeat right;
}
.join-us-driver {
    background: url("../../../../../Assets/Images/car pc.png") no-repeat right;
}
.join-us-header {
    font-family: 'Conv_Comfortaa-Regular';
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #262626;
    margin-bottom: 14px;
}

.join-us-subheader {
    font-size: 18px;
    line-height: 21px;
    color: #262626;
    margin-bottom: 59px;
}

@media (max-width: 1440px) {
    .join-us {
        min-height: 500px;
        background-size: cover;
    }
}

@media (max-width: 991px) {
    .join-us-sender {
        background: url("../../../../../Assets/Images/motor mobile.png") no-repeat bottom;
        background-position: 0% 60%;
    }
    .join-us-driver {
        background: url("../../../../../Assets/Images/car mobile.png") no-repeat right;
    }
    .join-us {
        height: 650px;
        background-size: cover;
        padding: 34px 14px;
    }

    .join-us .w-50 {
        width: 100% !important;
    }
}

@media (max-width: 767px) {
    .join-us-header {
        font-size: 28px;
        line-height: 31px;
        text-align: center;
    }
    .join-us-subheader {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 45px;
    }
}
.navbar {
  min-height: 75px;
  background: #ffffff;
}


.nav-link {
  font-size: 18px;
  line-height: 21px;
  color: #262626;
  cursor: pointer;
}

.navbar-nav {
  margin-left: auto !important;
}

.navbar-nav .nav-item:not(:last-child) {
  /* margin-right: 44px; */
}

button.navbar-toggler {
  width: auto;
}

.navbar-collapse {
  z-index: 1;
  background: rgb(255, 255, 255);
}

.navbar .navbar-toggler {
  border: none;
}

@media (min-width: 1440px) {
  .container {
    max-width: 1360px;
  }
}

/* @media (min-width: 991px) {

  .navbar .container {
    display: flex;
    flex-wrap: unset !important;
  }

  button.navbar-toggler {
    width: 0;
  }
}

@media (min-width: 768px) {

  .navbar .container {
    display: flex;
    flex-wrap: unset !important;
  }

  button.navbar-toggler {
    width: 6%;
  }
} */
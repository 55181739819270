section.top_bar {
    background: #8bc34a;
    position: fixed;
    top: 0;
    right: 0;
    left: 260px;
    display: flex;
    padding: 9px 25px;
    grid-gap: 20px;
    grid-gap: 20px;
    grid-gap: 20px;
    grid-gap: 20px;
    grid-gap: 20px;
    grid-gap: 20px;
    align-items: center;
    justify-content: space-between;
}

section.active_top_bar {
    background: #8bc34a;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    padding: 9px 25px;
    grid-gap: 20px;
    grid-gap: 20px;
    grid-gap: 20px;
    grid-gap: 20px;
    grid-gap: 20px;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}

.Search_box {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    background: #fff;
    border-radius: 5px;
}

.hamburger i {
    font-size: 25px;
    cursor: pointer;
}

.Search_box i {
    font-size: 16px;
    margin-left: 10px;
}

.Search_box input {
    outline: none;
    border: 0;
    padding: 6px 17px;
    background: transparent;
}


.top_bar_left_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    gap: 25px;
}

.top_bar_right_menu {
    display: flex;
    justify-content: center;
    align-items: inherit;
    grid-gap: 10px;
    gap: 10px;
}

.drop_down {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.header_icons {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 30px;
    gap: 45px;
}

.btn-success {
    color: #fff;
    background-color: transparent !important;
    border-color: none !important;
    outline: none !important;
    border: 0 !important;
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.header_icons i {
    font-size: 24px;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.drop_down i {
    font-size: 18px;
}

.header_profile_img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.dropdown-item {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 10px !important;
}

@media only screen and (max-width: 575px) {
    .header_icons i {
        font-size: 20px;
    }

    .top_bar_right_menu {
        gap: 0px;
    }

    section.top_bar {
        left: 184px;
    }

    .top_bar_right_menu .dropdown {
        width: 50px !important;
    }

    .section.active_top_bar {
        padding: 9px 10px !important;
    }
}
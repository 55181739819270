.modal-title {
    width: 100%;
}

.modal-header .close {
    padding: 0;
    margin: 0;
    text-align: -webkit-right;
}

.first_input,
.second_input,
.third_input {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.order,
.date_section,
.priority_section,
.from_take_section,
.to_take_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: '100%';
}

.location_section {
    align-items: center;
    display: flex;
}

.first_input,
.order_type_section,
.priority_section label {
    margin: 0;
}

select#ordertype {
    width: 200px;
    height: 35px;
    padding: 0px 15px;
    outline: none;
}

.main_form input {
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    outline: none;
}

.main_form label {
    margin: 0;
    padding: 0;
    width: 150px;
    align-self: flex-start;
}

.modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
}

.priority_section select {
    width: 100% !important;
    height: 37px;
    padding: 0px 15px;
    margin-top: 0px !important;
    outline: none;
}

form.main_form>div {
    margin-bottom: 20px;
    align-items: flex-start;
}

.location_section {
    width: 100%;
}

.location_section input {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 350px !important;
    padding: 0px 15px;
    outline: none;
}

.kilometer_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100% !important;
    margin-bottom: 10px;
}

.error_color {
    color: red;
}

.items.add_items i {
    display: flex;
    border: 1px solid #466225;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
}

.input_icons_section.location_input_section {
    padding-bottom: 10px;
    width: 100% !important;
}

.input_icons_section {
    display: flex;
}

.location_section label {
    float: left;
    margin-right: 0px;
}

.items.add_items {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
}

/* .input_icons_section:last-child .item_xmark {
    margin-left: 5px;
} */

.input_icons_section:last-child .item_xmark {
    margin-left: 0px;
}

.location_section label {
    float: left;
    margin-right: 55px;
}

.add_items {
    padding: 0px;
}

.location_section {
    display: block;
    align-items: center;
    justify-content: space-between;
}

.locatin_fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 99px;
}

.icons {
    width: 60px;
    display: flex;
}

.icons i {
    background: #fff;
    border: 1px solid;
    width: 30px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notes_section input {
    width: 100% !important;
}

.notes_section label {
    width: 114px !important;
}

.pac-container {
    z-index: 999999;
}

.notes_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal_buttons_container {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
}

.modal_buttons button {
    padding: 0px 63px !important;
    margin: -4px;
}

.modal_buttons {
    gap: 20px;
}

.modal_buttons .active {
    color: #fff !important;
    background: #8bc34a !important;
    outline: #8bc34a !important;
}

button.close_modal.btn.btn-primary:active {
    background: gray !important;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
    color: white;
    background: grey;
    border: none;
    font-weight: 500;
}
.btn_optimise {
    color: #fff !important;
    font-size: 14px !important;
    background: #8bc34a !important;
    width: 100px !important;
}

.location_section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input_label_section {
    width: 100% !important;
    gap: 120px !important;
    margin-left: 100px !important;
    margin-top: 10px !important;
}

.input_label_section p {
    margin: 0;
    padding-bottom: 10px;
}

select#vehicle {
    width: 100% !important;
    height: 37px;
    padding: 0px 15px;
    outline: none;
}

.vehical-select {
    display: flex;
    gap: 0px !important;
    align-items: center;
    width: 324px;
}
select#weight {
    width: 100% !important;
    height: 37px;
    padding: 0px 15px;
    outline: none;
}
.select-weight {
    display: flex;
    gap: 0px !important;
    align-items: center;
    width: 324px;
}
.km_section {
    display: flex;
    align-items: center;
    position: relative;
}

.kilometer {
    width: 114px !important;
}

span.km_value {
    border-bottom: 1px solid #8bc34a;
}

.fa-close:before,
.fa-multiply:before,
.fa-remove:before,
.fa-times:before,
.fa-xmark:before {
    content: "\f00d";
    top: 2px !important;
    position: relative !important;
}

.fa-add:before,
.fa-plus:before {
    content: "\2b";
    position: relative !important;
    top: 2px !important;
}

@media only screen and (max-width: 1199px) {
    .main_form input {
        width: 100%;
    }

    .first_input,
    .second_input,
    .third_input {
        flex-direction: column;
    }
    .vehical-select {
        width: 100% !important;
    }
    .select-weight {
        width: 100% !important;
        margin-top: 10px;
    }
    .location_section input {
        width: 100% !important;
    }
    .order,
    .date_section,
    .priority_section,
    .from_take_section,
    .to_take_section {
        width: 100% !important;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    form.main_form>div {
        margin-bottom: 0px;
    }

    form.main_form>div {
        margin-bottom: 5px !important;
    }

    .main_form label {
        width: 150px;
    }

    .order {
        margin-bottom: 10px;
    }


    .input_icons_section {
        width: 99%;
        margin-bottom: 5px;
    }

    .location_section label {
        float: left;
        margin-right: 0px;
    }

    .input_icons_section {
        width: 100%;
        display: flex;
        align-items: baseline;
    }

    select#ordertype {
        width: 180px !important;
    }

    .input_label_section {
        gap: 100px !important;
        margin-left: 130px !important;
        margin-top: 10px !important;
    }

    .location-search-input {
        width: 100% !important;
    }
    input.location-search-input.select_input {
        width: 100% !important;
    }
    .item.items_plus {
        width: 30px;
        height: 30px;
    }
    .kilometer_div {
        margin-top: 5px;
    }
    .kilometer {
        width: 140px !important;
    }
    .notes_section label {
        width: 150px !important;
    }
    .locatin_fields {
        display: flex;
        margin-left: 124px;
    }
}

@media only screen and (max-width: 375px) {
    select#ordertype {
        width: 111px;
    }
}


@media only screen and (max-width: 768px) {
    .main_form label {
        width: 150px;
    }

    .location_section label {
        float: left;
    }

    .input_icons_section {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .location_section input {
        flex: -1 0 100%;
        width: 266px !important;
    }

    .location_section select {
        width: 100% !important;
    }

    select#ordertype {
        width: 69px;
    }

    input.location-search-input.select_input {
        width: 255px !important;
    }
    
    .locatin_fields {
        display: flex;
    }

}

@media only screen and (max-width: 991px) {
    .location_section label {
        float: left;
        margin-right: -37px;
    }
    select#ordertype {
        width: 114px !important;
    }
    .kilometer_div {
        align-self: flex-start;
        margin-top: 10px;
    }
    .kilometer {
        width: 130px !important;
    }
    .locatin_fields {
        margin-left: 0px;
    }
    .input_label_section {
        gap: 36px !important;
        margin-left: 0px !important;
    }
}

@media only screen and (max-width: 320px) {
    .location_section input {
        width: 200px !important;
    }
}

@media only screen and (max-width: 375px) {
    .location_section input {
        width: 100%;
    }
}

@media only screen and (max-width: 425px) {
    .location_section input {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 575px) {
    .order {
        flex-direction: column;
    }

    .main_form label {
        width: 120px;
        padding-bottom: 1px;
        align-self: flex-start;
    }
    .select-weight {
        flex-direction: row !important;
    }
    .order,
    .date_section,
    .order_type_section,
    .priority_section,
    .from_take_section,
    .to_take_section {
        flex-direction: column;
    }

    form.main_form>div {
        margin-bottom: 5px !important;
        flex-direction: column;
    }

    .main_form input {
        height: 30px;
    }

    select#ordertype {
        height: 30px;
        width: 100px !important;
    }

    .icons i {
        width: 30px;
        height: 30px;

    }

    .modal_buttons button {
        padding: 0px 0px !important;
        margin: -4px;
        max-width: 100px;
        height: 37px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: 12px !important;
    }
   
    .btn_optimise {
        max-width: 100px;
        height: 37px;
        margin: 0 20px 0 0;
        font-size: 12px !important;
    }

    .modal-footer {
        margin: 0 auto;
    }

    button.close_modal.btn.btn-primary {
        font-size: 12px !important;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        font-size: 14px;
    }

    .input_icons_section {
        width: 100% !important;
        margin-left: 0;
    }
    .input_icons_section.location_input_section {
        padding-bottom: 10px;
        width: 100% !important;
    }

    .location_section label {
        margin-right: 0px;
    }

    .items.add_items i {
        width: 30px;
        height: 30px;
    }

    .input_label_section p {
        font-size: 14px;
    }

    select#ordertype {
        width: 41px;
    }

    .location-search-input {
        width: 100% !important;
    }

    .items.add_items i {
        width: 20px;
        height: 20px;
        font-size: 10px;
    }

    .add_items {
        padding: 0 2px;
    }

    .input_label_section {
        display: flex;
        gap: 34px !important;
        margin-top: 15px !important;
    }
    .kilometer_div {
        flex-direction: row;
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
    .kilometer_div_body > span:nth-child(2) {
        padding-left: 10px;
    }
    
    .kilometer {
        width: 90px !important;
    }
    input.location-search-input.select_input {
        width: 100% !important;
    }
}
.sender {
    padding: 68px 0;
}

.sender-tab {
    margin-bottom: 64px;
}

.sender-tab .sender-name {
    font-family: 'Conv_Comfortaa-Regular';
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #262626;
    position: relative;
    cursor: pointer;
}

.sender-name:not(:last-child) {
    margin-right: 330px;
}

.sender-name.active::after {
    position: absolute;
    content: "";
    background: #0bdc00;
    border-radius: 27px;
    height: 5px;
    width: calc(100% + 70px);
    bottom: -5px;
    transform: translateX(-50%);
    left: 50%;
}

.sender-btn {
    margin-top: 83px;
}

.sender-btn .store-btn:first-child {
    margin-right: 100px;
}

.sender-item-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    width: 205px;
    color: #000000;
}
.sender-slider{
    height: 550px !important;
}
.sender-driver{
    height: 550px !important;
}

@media (max-width: 991px) {
    .sender-btn .store-btn:first-child {
        margin-right: 40px;
    }
    .sender-name:not(:last-child) {
        margin-right: 50px;
    }
    .sender-tab {
        justify-content: space-around;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .sender-name.active::after {
        width: calc(100% + 35px);
    }
    .sender-tab {
        justify-content: space-between;
        width: 90%;
        margin: 0 auto 64px;
    }
    .sender-tab  .sender-name {
        font-size: 28px;
        line-height: 31px;
    }
    .sender-tab {
        justify-content: space-around;
        width: 100%;
    }
}
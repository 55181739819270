body {
    overflow-x: hidden;
}

.middel_component {
    background: white;
}

section.middel_component_header p {
    margin: 0;
}


section.middel_component_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25px;
    padding-top: 25px;
}


.dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-size: 18px;
    font-weight: 500;
}


.dashboard i {
    background: #8bc34a;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
}


.overview_text p {
    font-size: 18px;
    font-weight: 500;
}


.middel_component p {
    margin: 0;
}


.calender_section {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px 25px;
}


.date_picker {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}


.order_button {
    padding: 0px 25px;
    padding-top: 20px;
}


.tab_section {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: end;
    margin-bottom: 7px;
}


.tab_section button {
    padding: 9px 29px !important;
    border-radius: 4px !important;
    background: transparent !important;
    width: 20% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}


.tab_section .active {
    background: silver !important;
}


.tab_section .active i {
    color: #212529;
}

input[type="checkbox"] {
    position: relative;
    opacity: 1;
    z-index: 0;
}

.modal_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.dashboar_date_picker.form-group label {
    margin: 0;
}

.dashboar_date_picker.form-group input {
    margin: 0;
    padding: 0;
}



.form-group .form-control:not(:placeholder-shown) {
    padding-top: 0 !important;
    padding-bottom: 0px !important;
}


.dashboar_date_picker.form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1px;
    padding: 0px 25px;
}


.order_button h4 {
    font-size: 18px;
}

.tab_section .active {
    background: #8bc34a !important;
    color: #fff;
    font-weight: 500;
    border: none;
}

button.active.btn.btn-primary {
    width: 180px !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #000 !important;
    text-decoration: none !important;
    background: transparent !important;
}

@media only screen and (max-width: 575px) {
    .dashboard_middle_component {
        padding: 58px 0px 0px 0px !important;
    }
}
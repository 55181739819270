.data_table_main {
    display: flex;
    justify-content: space-between;
    padding: 0px 25px;
}

.search-bar-0:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: none !important;
    outline: none !important;
}

button#pageDropDown {
    background: #8bc34a !important;
    border: 0 !important;
    width: 60px !important;
    color: #fff !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #e1fbff !important;
    background-color: #8bc34a !important;
    border-color: #8bc34a !important;
    font-weight: 500;
}

.react-bootstrap-table {
    padding: 0px 25px !important;
    overflow: scroll;
}

.row.react-bootstrap-table-pagination {
    padding: 0px 25px !important;
}

.page-link {
    color: #000 !important;

}

.react-bootstrap-table table {
    overflow-x: auto !important;
}

span.react-bootstrap-table-pagination-total {
    display: none !important;
}

.btn-primary {
    color: #000;
    background-color: transparent;
    border-color: transparent;
}

.form-control:focus {
    outline: none;
    box-shadow: none !important;
}

.form-control:focus {
    color: #495057 !important;
    background-color: transparent !important;
    border-color: #000 !important;
    outline: none !important;
    box-shadow: 0 0 0 .2remrgba(0, 123, 255, .25);
}

.upper_search_component {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.editbutton {
    color: #fff;
    background: #8bc34a;
    border-radius: 6px;
}

button.delet_button {
    background: #8bc34a !important;
    color: #fff !important;
    padding: 10px 90px !important;
}

/**
 * customizing toastify
 */
.Toastify__close-button {
    display: flex !important;
    justify-content: right !important;
    padding-right: 5px !important;
    width: 30px !important;
    height: 20px !important;
    padding-top: 5px !important;
}

.Toastify__toast-body {
    color: red !important;
}

.Toastify__progress-bar {
    display: none !important;
}

@media only screen and (max-width: 991px) {
    table {
        width: 1100px !important;
    }
}

@media only screen and (max-width: 576px) {
    button.active.btn.btn-primary {
        width: 100px !important;
        font-size: 14px;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        font-size: 10px;
    }

    .Search_box input {
        width: 100%;
    }

    .header_icons i {
        font-size: 22px;
    }

    .Search_box input {
        padding: 6px 0px;
    }

    .tab_section {
        width: 100%;
    }

    .tab_section button {
        padding: 5px 14px !important;
    }

    span.react-bootstrap-table-pagination-total {
        display: none !important;
    }

    .row.react-bootstrap-table-pagination {
        justify-content: flex-start !important;
        width: 330px;
    }

    .react-bootstrap-table-pagination-list {
        margin-top: 10px;
        width: fit-content !important;
    }

    button#pageDropDown {
        background: #8bc34a !important;
        border: 0 !important;
        width: 20% !important;
    }

    .hamburger i {
        font-size: 20px;
    }

    section.active_top_bar {
        padding: 9px 25px !important;
    }
}
.contact-us {
    background-size: cover;
    padding: 71px 0 43px;
}

.contact-us-header {
    font-family: Conv_Comfortaa-Bold;
    font-size: 36px;
    line-height: 40px;
    color: #262626;
    margin-bottom: 10px;
}

.contact-us-subheader {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #262626;
    margin-bottom: 10px;
}


@media (max-width: 767px) {
    .contact-us {
        background: transparent;
        padding: 60px 0 121px;
    }
}
.form {
    background: #ffffff;
    border-radius: 20px;
    padding: 20px;
    width: 450px;
    margin-left: auto;
}

@media (max-width: 991px) {
    .form {
        width: 100%;
        margin: 0;
        padding: 20px;
    }
}

.kiwi-modal .modal-content {
    box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.19);
    border-radius: 20px;
}
.kiwi-modal .modal-dialog {
    max-width: 975px;
}
.kiwi-modal .modal-header {
    border: none;
}
.kiwi-modal  .modal-body {
    padding: 26px 70px 82px;
}

.joinUs-subheader {
    font-size: 18px;
    line-height: 21px;
    color: #262626;
    margin-bottom: 36px;
}

.joinUs-header {
    font-family: 'Conv_Comfortaa-Regular';
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #262626;
    margin-bottom: 20px;
}

@media screen and (max-width: 767px){
    .kiwi-modal .modal-content {
        height: 90vh;
        overflow: auto;
    }
    .kiwi-modal  .modal-body {
        padding: 26px 20px 82px;
    }
    .joinUs-header {
        margin-bottom: 0;
    }
    .joinUs-subheader {
        margin-bottom: 30px;
    }
    .recaptcha-tag {
        transform: scale(0.8);
        margin-left: -30px;
    }
}
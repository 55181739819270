@import './Assets/font/Comfortaa/fonts.css';

body {
  margin: 0;
   font-family: 'Conv_Comfortaa-Regular','Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Conv_Comfortaa-Regular', 'Menlo', 'Monaco', 'Consolas', 'Courier New',
    monospace;
}

button:focus {
    outline: none !important;
}

.btn-green ,.btn.btn-green{
    background: #0bdc00;
    border-radius: 100px;
    height: 56px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #262626;
    text-transform: capitalize;
    width: 295px;
}
.btn-green:hover ,.btn.btn-green:hover{
    background: #0bdc00;
    border-radius: 100px;
    border: none;
    color: #262626;
}

.btn-green-outline {
    border: 3px solid #0BDC00;
    border-radius: 100px;
    height: 56px;
    background: transparent;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #F4F1F8;
    text-transform: capitalize;
    width: 295px;
}

@media screen and (max-width: 375px){
    .btn-green,
    .btn-green-outline,.btn.btn-green {
        width: 240px;
    }
}

@media (min-width: 1440px) {
    .container{
        max-width: 1400px !important;
    }
}
.feature {
    min-height: 160px;
    padding: 40px 0;
}
.feature-name {
    font-size: 24px;
    line-height: 28px;
    color: #262626;
}

@media (max-width: 991px) {
    .feature  img {
        height: 54px;
    }
    .feature-item {
        margin-bottom: 50px;
    }
    .feature-name {
        margin-top: 20px;
    }
}
.testimonal-section {
    background: url("../../../../../Assets/Images/testimonals.png") no-repeat;
    background-size: cover;
    position: relative;
    padding: 81px 0;
}

.testimonal-subHeader {
    font-size: 15px;
    line-height: 18px;
    color: #828282;
}

.testimonal-header {
    font-size: 18px;
    line-height: 21px;
    color: #262626;
    margin-bottom: 10px;
    width: 225px;
    font-weight: 500;
}
.overlay-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    inset: 0;
}
.carousel-caption {
    position: relative !important;
    right: 0 !important;
    bottom: 20px;
    left: 0 !important;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: start !important;
}
.carousel.slide{
    height: 220px;
}
.carousel-indicators li {
    width: 15px !important;
    height: 15px !important;
    background-color: transparent !important;
    border: 2px solid #828282 !important;
    border-radius: 23px !important;
    background-clip: unset !important;
    opacity: 1 !important   ;
}
.carousel-indicators {
    bottom: -40px !important;
}
.carousel-indicators .active {
    background-color: #828282 !important;
}

@media (max-width: 767px) {
    .testimonal-section {
        padding: 65px 15px;
    }
}
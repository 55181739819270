.from_section {
    background: #ffffff;
    width: 500px;
    margin: 0 auto;
    padding: 20px 30px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-top: 50px;
}

.from_section h4 {
    padding-bottom: 20px;
    color: #0bdc00;
    font-size: 38px;
    font-weight: 700;
}

.Para_firts_text p,
.para_second_text p {
    padding: 0;
    margin: 0;
}

.Para_firts_text p,
.para_second_text p {
    padding: 0;
    margin: 0;
}

.Para_firts_text p {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 8px;
    color: #000;
}

.para_second_text p {
    font-size: 20px;
    font-weight: 400;
}

.input-container input {
    width: 100%;
    height: 51.1px;
    margin-bottom: 11px;
    border: 0;
    background: #fff !important;
    outline: none;
    margin: 0;
    margin-bottom: 15px;
    border: 1px solid rgb(0 0 0 / 25%);
    padding: 22px;
}

.para_second_text p {
    margin-bottom: 15px;
    color: #000;
}

button {
    width: 100%;
    border: 0;
    border-radius: 3px;
    height: 41px;
    font-weight: 600;
    background: #0bdc00;
}

.input_checkbox {
    opacity: 1 !important;
    z-index: 0 !important;
}

.input_box label {
    margin: 0;
    padding: 0;
}

.main_inputbox_forget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.input_box {
    display: flex;
    align-items: center;
}

.input_box label {
    margin-left: 16px;
    font-size: 15px;
    color: hwb(0deg 0% 100% / 73%);
}

.forget_password a {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.signup_link p {
    margin: 0;
    padding: 0;
    /* display: flex; */
    color: #000000d1;
}

.signup_link {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    font-size: 17px;
    font-weight: 500;
    grid-gap: 12px;
    gap: 12px;
}

span.error {
    color: red;
    font-weight: 500;
    position: relative;
    top: -15px;
}

.body {
    color: #f2edf3;
}

.signup_link a {
    color: #0bdc00 !important;
}

.login-error {
    color: red;
    display: flex;
    font-weight: 500;
    padding-top: 50px;
    justify-content: center;
    align-items: center;
}
.overlay-bg-black {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    inset: 0;
}

.form {
    margin: 0 auto !important;
}

.from_section h4 {
    padding-bottom: 20px;
    color: #0bdc00;
    font-size: 38px;
    font-weight: 700;
}

.from_section {
    margin-bottom: 116px;
    border-radius: 20px;
}

.sign_up_text p {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    color: #0bdc00;
}

.input_section {
    display: flex;
    align-items: center;
    gap: 30px;
}

.label_checkbox_section label {
    padding: 0;
    margin: 0;
}
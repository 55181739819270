.modal-title {
    width: 100%;
}

.modal-header .close {
    padding: 0;
    margin: 0;
    text-align: -webkit-right;
}

.first_input,
.second_input,
.third_input {
    display: flex;
    justify-content: space-between;
}

.order,
.date_section,
.order_type_section,
.priority_section,
.from_take_section,
.to_take_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 324px;
}

.location_section {
    align-items: center;
    display: flex;
}

.first_input,
.order_type_section,
.priority_section label {
    margin: 0;
}

select#ordertype {
    width: 200px;
    height: 35px;
    padding: 0px 15px;
    outline: none;
}

.paypal_main_form input {
    width: 200px;
    height: 35px;
    padding: 0px 15px;
    outline: none;
}

.paypal_main_form label {
    margin: 0;
    padding: 0;
}

.modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
}

.priority_section select {
    width: 200px;
    height: 37px;
    padding: 0px 15px;
    outline: none;
}

form.paypal_main_form>div {
    margin-bottom: 20px;
    align-items: flex-start;
    min-height: 700px !important;
}

.location_section {
    width: 100%;
}

.location_section input {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 366px;
    padding: 0px 15px;
    outline: none;
}

.items.add_items i {
    display: flex;
    border: 1px solid #466225;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
}

.input_icons_section.location_input_section {
    padding-bottom: 10px;
}

/* .items.add_items {
    display: flex;
    align-items: center;
} */

.items.add_items {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
}

/* .input_icons_section:last-child .item_xmark {
    margin-left: 5px;
} */

.input_icons_section:last-child .item_xmark {
    margin-left: 0px;
}

.location_section label {
    float: left;
    margin-right: 55px;
}

.add_items {
    padding: 0px;
}

.location_section {
    display: block;
    align-items: center;
    justify-content: space-between;
}

.locatin_fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.input_icons_section {
    display: flex;
    align-items: flex-start;
    width: 68%;
    margin: 0 auto;
    grid-gap: 10px;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.icons {
    width: 60px;
    display: flex;
}

.icons i {
    background: #fff;
    border: 1px solid;
    width: 30px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notes_section input {
    width: 84%;
}

.pac-container {
    z-index: 999999;
}

.notes_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal_buttons button {
    padding: 0px 63px !important;
    margin: -4px;
}

.modal_buttons {
    gap: 20px;
}

.modal_buttons .active {
    color: #fff !important;
    background: #8bc34a !important;
    outline: #8bc34a !important;
}

button.close_modal.btn.btn-primary:active {
    background: gray !important;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
    color: white;
    background: grey;
    border: none;
    font-weight: 500;
}

.location_section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input_label_section {
    width: 510px;
    margin: 0 auto;
    display: flex;
    gap: 123px;
}

.input_label_section p {
    margin: 0;
    padding-bottom: 10px;
}

select#vehicle {
    /* padding: 10px 38px; */
    width: 200px;
    height: 37px;
    padding: 0px 15px;
    outline: none;
}

.vehical-select {
    display: flex;
    align-items: center;
    gap: 63px;
}

select#weight {
    width: 200px;
    height: 37px;
    padding: 0px 15px;
    outline: none;
}

.km_section {
    display: flex;
    align-items: center;
    gap: 50px;
    position: relative;
}

span.km_value {
    border-bottom: 1px solid #8bc34a;
}

.fa-close:before,
.fa-multiply:before,
.fa-remove:before,
.fa-times:before,
.fa-xmark:before {
    content: "\f00d";
    top: 2px !important;
    position: relative !important;
}

.fa-add:before,
.fa-plus:before {
    content: "\2b";
    position: relative !important;
    top: 2px !important;
}

@media only screen and (max-width: 1199px) {
    .paypal_main_form input {
        width: 100%;
    }

    .first_input,
    .second_input,
    .third_input {
        flex-direction: column;
    }

    .order,
    .date_section,
    .order_type_section,
    .priority_section,
    .from_take_section,
    .to_take_section {
        width: 100% !important;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    form.paypal_main_form>div {
        margin-bottom: 0px;
    }

    select#ordertype {
        width: 100%;
    }

    form.paypal_main_form>div {
        margin-bottom: 5px !important;
    }

    .paypal_main_form label {
        width: 150px;
    }

    .order {
        margin-bottom: 10px;
    }

    .priority_section select {
        width: 750px;
        margin-top: 5px;
    }

    .input_icons_section {
        width: 99%;
        margin-bottom: 5px;
    }

    .location_section label {
        float: left;
        margin-right: 0px;
    }

    .input_icons_section {
        width: 100%;
        display: flex;
        align-items: baseline;
    }

    select#ordertype {
        width: 114px;
    }

    .input_label_section {
        width: 100%;
    }

    .location-search-input {
        width: 100% !important;
    }
    input.location-search-input.select_input {
        width: 100% !important;
    }
}

@media only screen and (max-width: 375px) {
    select#ordertype {
        width: 111px;
    }
}


@media only screen and (max-width: 768px) {
    .location_section label {
        float: left;
    }

    .input_icons_section {
        width: 90%;
    }

    .priority_section select {
        width: 462px;
    }

    .location_section input {
        flex: -1 0 100%;
    }

    .input_icons_section {
        margin-left: 25px;
    }

    .location_section input {
        width: 266px !important;
    }

    select#ordertype {
        width: 69px;
    }

    input.location-search-input.select_input {
        width: 235px !important;
    }

    .input_icons_section {
        width: 88% !important;
    }

}

@media only screen and (max-width: 991px) {
    .location_section label {
        float: left;
        margin-right: -37px;
    }


}

@media only screen and (max-width: 320px) {
    .location_section input {
        width: 200px !important;
    }
}

@media only screen and (max-width: 375px) {
    .location_section input {
        width: 100%;
    }
}

@media only screen and (max-width: 425px) {
    .location_section input {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 575px) {
    .order {
        flex-direction: column;
    }

    .paypal_main_form label {
        width: 100%;
        padding-bottom: 1px;
    }

    .order,
    .date_section,
    .order_type_section,
    .priority_section,
    .from_take_section,
    .to_take_section {
        flex-direction: column;
    }

    .priority_section select {
        width: 100%;
        margin-top: 5px;
    }

    form.paypal_main_form>div {
        margin-bottom: 5px !important;
        flex-direction: column;
        min-height: 500px !important;
    }

    .paypal_main_form input {
        height: 30px;
    }

    select#ordertype {
        height: 30px;
        width: 100px !important;
    }

    .priority_section select {
        height: 30px;
    }

    .icons i {
        width: 30px;
        height: 30px;

    }

    .modal_buttons button {
        padding: 0px 0px !important;
        margin: -4px;
    }

    .modal_buttons button {
        padding: 0px 63px !important;
        margin: -4px;
        width: 30px;
        height: 37px;
    }

    .modal_buttons button {
        padding: 0px 0px !important;
        margin: -4px;
        width: 100px;
        height: 37px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .modal-footer {
        margin: 0 auto;
    }

    button.close_modal.btn.btn-primary {
        font-size: 14px;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        font-size: 14px;
    }

    .locatin_fields {
        display: block;
    }

    .input_icons_section {
        width: 100%;
        margin-left: 0;
    }

    .location_section label {
        margin-right: 0px;
    }

    .items.add_items i {
        width: 30px;
        height: 30px;
    }

    .input_label_section p {
        font-size: 14px;
    }

    select#ordertype {
        width: 41px;
    }
    input.location-search-input.select_input {
        width: 100% !important;
    }
    .items.add_items i {
        width: 20px;
        height: 20px;
        font-size: 10px;
    }
    .add_items {
        padding: 0 2px;
    }

    .input_label_section {
        display: flex;
        gap: 13px;
    }


}